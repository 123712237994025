import Modal from 'lego-framework/src/containers/Modal';

export default {
    extends: Modal,
    name: 'disable-modal',
    props: {
        width: { type: String, default: '25r' },
        dialog: { type: Boolean, default: true },
        headerColor: { type: String, default: 'danger' },
        bodyColor: { type: String, default: 'danger' },
        okButtonColor: { type: String, default: 'danger' },
        okButton: { type: String, default: 'Disable' },

        cancelButtonColor: { type: String, default: 'info' }
    }
};
