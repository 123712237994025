<template>
    <div>
        <modal title="Update" id="modal" ref="modal" width="25r">
            <s-form role="form" @submit="EditShopProduct" ref="formValidator">
                <div class="row">
                    <div class="col-md-12">
                        <p class="bg-info card">Please enter the price in <strong>
                            Paisa
                        </strong></p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <validated-input name="SellingPrice" label="Selling Price"
                                         v-model="model.selling_price"/>
                    </div>
                    <div class="col-md-6">
                        <validated-input name="DisplayPrice" label="Display Price"
                                         v-model="model.display_price"/>
                    </div>
                    <div class="col-md-6 d-none" style="display: none">
                        <validated-input name="shopid" label="Shop" v-model="model.shop_id"/>
                    </div>
                    <div class="col-md-12">
                        <btn type="submit" @click="EditShopProduct">
                            Save
                        </btn>
                    </div>

                </div>
            </s-form>
        </modal>

        <enable-modal dialog title="Enable Product" ref="enableModal">
            Are you sure ?
        </enable-modal>

        <disable-modal dialog title="Disable Product" ref="disableModal">
            Are you sure ?
        </disable-modal>

        <validated-input icon="fa fa-search" v-model="search" clear-icon="fa fa-close"/>
        <infinite-scroll-container class="product-list" @load-next="loadNext">
            <div class="product-card bs-1" v-for="(product, index) in products" v-bind:key="index">
                <img src="../../../assets/img/No_image_available.svg" class="product-img" :alt="product.name"
                     v-if="!product.img"/>
                <img :src="product.img" alt="Image" class="product-img" v-else>
                <strong class="text-uppercase mt-2 text-primary">{{ product.name }}</strong>
                <hr/>
                <div class="product-footer">
                    <div class="price">
                        Rs.
                        <cur class="display-price" :value="product.display_price"
                             v-if="product.display_price !== product.selling_price"/>
                        <cur class="selling-price" :value="product.selling_price"/>
                    </div>
                    <div class="add-to-cart my-3 bs-11" :class="product.loading ? 'loading': ''">
                        <div class="btn-group-tight">
                            <c-btn color="warning" icon="fa fa-pencil" @click.prevent="EditItem(product)"/>
                            <c-btn v-if="product.enabled === 'false'" color="success" icon="fa fa-power-off"
                                   @click.prevent="productSwitch(product)"/>
                            <c-btn v-if="product.enabled === 'true'" color="danger" icon="fa fa-power-off"
                                   @click.prevent="productSwitch(product)"/>
                        </div>

                    </div>
                </div>
            </div>
        </infinite-scroll-container>
        <Loader v-if="loading"></Loader>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';
import { mapActions, mapGetters } from 'vuex';
import CardButton from '../../../components/CardButton';
import Loader from '../../../views/loader/loader';
import InfiniteScrollContainer from '../../../components/InfiniteScroll';
import debounce from 'lodash.debounce';
import Cur from '../../../components/Currency';
import EnableModal from '../../../components/EnableModal';
import DisableModal from '../../../components/DisableModal';

export default {
    name: 'ShopCatalog',
    components: {
        DisableModal,
        EnableModal,
        Cur,
        InfiniteScrollContainer,
        'c-btn': CardButton,
        Loader
    },
    data () {
        return {
            product: '',
            loading: false,
            prodLoading: false,
            page: 0,
            isLast: false,
            search: '',
            model: {
                id: '',
                selling_price: '',
                display_price: '',
                shop_id: ''
            },
            rules: {
                name: {
                    required: true
                },
                description: {
                    required: true
                },
                category: {
                    required: true
                },
                price: {
                    required: true
                }
            },
            products: [],
            data: []
        };
    },

    watch: {
        search: debounce(function () {
            this.products = [];
            this.page = 0;
            this.isLast = false;
            this.loadNext();
        }, 500)
    },

    computed: {
        ...mapActions(['setCart']),
        ...mapGetters(['currentUser'])
    },

    methods: {
        setData (response) {
            const items = response.data.data;
            if (items.length === 0) {
                this.isLast = true;
                return;
            }
            if (items) {
                this.products = [
                    ...this.products,
                    ...items
                ];
            }
        },
        async loadData () {
            this.loading = true;
            const response = await axios.get(urls.shopProduct.shopProductAdminList, {
                params: {
                    search: this.search,
                    id: 1,
                    page: this.page,
                    per_page: 20
                }
            });
            this.loading = false;
            this.setData(response);
        },

        async loadNext () {
            if (this.loading) {
                return;
            }
            if (this.isLast) {
                return;
            }
            this.page += 1;
            await this.loadData();
        },

        async productSwitch (item) {
            let modal;
            if (item.enabled === 'true') {
                modal = this.$refs.disableModal;
            } else {
                modal = this.$refs.enableModal;
            }

            const response = await modal.show();
            if (response === false) {
                return;
            }

            const url = urls.shopProduct.EnableOrDisable;
            this.$set(item, 'loading', true);
            axios.form(url, {
                id: item.id,
                shop_id: item.shop_id
            });
            this.$set(item, 'loading', false);
            console.log('item', item);
            if (item.enabled === 'true') {
                this.$set(item, 'enabled', 'false');
            } else {
                this.$set(item, 'enabled', 'true');
            }
        },

        EditItem (item) {
            this.model = {
                id: item.id,
                selling_price: item.selling_price,
                display_price: item.display_price,
                shop_id: item.shop_id
            };
            this.openModal();
        },

        async EditShopProduct () {
            const url = urls.shopProduct.update;
            const response = await axios.form(url, this.model);

            if (response.data.success) {
                const arr = this.products;
                const index = arr.map(function (o) {
                    return o.id;
                }).indexOf(this.model.id);

                const newProduct = {
                    ...arr[index],
                    ...this.model
                };
                this.model = {};

                arr.splice(index, 1);
                arr.splice(index, 0, newProduct);
            }
            this.$refs.modal.close();
        },
        openModal () {
            this.$refs.modal.show();
        }
    }
}
;

</script>
